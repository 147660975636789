/* Resets */
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
  }

  img{max-width:100%; align-self: flex-start;}

  /* End Resets */

.App{
  background-color: #282c34;
  color: white;
  font-size: .95rem;
  line-height:1.5;
  min-height: 100vh;
  margin:auto;

  font-family: "interstate-mono", monospace;
}

a{color:inherit; text-decoration: none;}

h1,h2,h3,h4{font-family: "interstate-mono", monospace;}

h3{font-size:28px; line-height: 1.2;}

.half .section{
  max-width:540px; 
  margin-left:auto;
  margin-right:auto;
}

.hide{display:none!important;}
.uppercase{text-transform:uppercase;}


.App.theme--light{
  background-color: #F7F1E8;
  color: #0B0B0B;
}
.logo{width:220px;}

.logos-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom:12px;
}
.powered-by-logo-wrapper{
  font-size: .7rem;
}
.powered-by-text{
  text-transform: uppercase;
  margin-top: -2.6em;
  margin-bottom: 6px;
  text-align: right;
}

.logos-wrapper .send-logo{width:120px;}

.page-communities{
  background-image: url(images/fmm-bg-arrow-tan.png);
  background-repeat: no-repeat;
  background-position: left -5% bottom;
  background-size: 75% auto;
}
.page-communities .welcome-p{max-width: 29em; margin-bottom: 34px;}

.container{margin-left:auto; margin-right:auto; padding:15px; max-width:1200px;}
.page-single-community .container{max-width:1100px;}

.content{ padding:20px; padding-top:80px;}
.v-center .content{padding-bottom: 90px;}

.row{
  display: flex;
  flex-wrap: wrap;
  gap:10%;
}
.col{flex: 1;}

.v-center{display:flex; align-items: center;justify-content: center; min-height: 100vh;}

.home-main-row{display:grid; grid-template-columns: 33% 1fr; gap: 5%;}
.aside{
  padding-top: 80px;
  background-image: url(images/fmm-take-action-emblem-black@2x.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 180px auto;
  margin-bottom: 55px;
  padding-bottom: 42px;
}
.aside .background-container{
  background-color: #171717;
  position:sticky; 
  top: 33px; 
  background-image:url(images/fmm-aside-bg@2x.jpg); 
  width:100%; 
  height:75vh;
  min-height: 400px; 
  background-size: cover; 
  background-position: center;
}

.aside-content{
  display:flex; 
  align-items: center; 
  justify-content: center; 
  text-align: center; 
  font-weight:600;
  color:#F5F5F5; 
  text-shadow: 0px 0px 18px rgba(0,0,0,.9);
  border: 3px solid #000; 
  overflow:hidden; 
  border-radius:30px;
  position: relative;
}

.aside-logos-inner{
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  padding: 17px;

}

.App-header {
  display: flex;
  flex-direction: column;
}

.community-card{
  background: #fff;
  border: 2px solid var(--border-color);
  border-radius:15px;
  margin-bottom:38px;
  display: flex;
  box-shadow: 0 11px 16px rgba(0,0,0,.06);
  letter-spacing:.04em;
  font-size: .82rem;
  position:relative;
  
  transition: transform .5s ease, box-shadow .5s ease;

  --roundness: 15px;
  --border-color: #010101;
}

.community__image{
  background-color:#E5E5E5;
  background-image: url(images/placeholder-tan-2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-basis: 40%; 
  border-radius: 12px 0 0 12px; 
  overflow: hidden; 
  border-right:2px solid var(--border-color); 
  position: relative;
  min-height: 200px;
}
.community__image img{display:block; height:100%; width:100%; object-fit: cover;}

.community__image-bg{width:100%; height:100%;}

.community-card .community__info{flex-basis: 60%; padding: 30px 24px;}
.community-card .community__meta .meta-item:last-child{margin-bottom:22px;}/* This method means if there is no metadata the margin won't show. */

.community-card.unified{position: relative; }
.community-card.unified .send-me-btn{position:absolute; right:3%; bottom: -24px; margin-top:18px;}
.community-card .com-label{
  opacity:.7;
  text-transform: uppercase;
  font-size: .6rem;
  font-weight: 600;
  letter-spacing: 0;
}

.name-affiation-wrapper{display:flex; justify-content: space-between; gap:2%;}


.community__name, .community__affiliation{font-weight: 600; margin-bottom:10px;  text-transform: uppercase;}
.community__name{font-size: 1.1rem;}
.community__affiliation{opacity:.5; font-size: .7rem;}

.affiliation-wrapper{text-align:right;}

.community__desc{
  /* CSS Method for truncating to x lines. */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom:2em;
}
.community__meta{
  font-size: 80%;
  display: grid;
  grid-template-columns: .95fr 1.05fr;
  gap: 1.6em 0.5em;
  margin-top:auto; margin-bottom:auto;
  text-transform:uppercase;
  letter-spacing:0;
  font-weight:bold;
}
.community__meta .com-label{margin-bottom:3px; opacity: .6;}

.meta-icon svg{
  width: auto;
    height: 1em;
    margin-right: 0.6em;
    top: 1px;
    position: relative;
    fill:currentColor!important;
}
.progress-bar{
  background:rgba(209,209,209,.5);
  border-radius: 20px;
  height:6px;
  overflow: hidden;
}

.progress-bar span{
  display: block;
  height: 100%;
  background: rgb(244,102,48);
  background: linear-gradient(90deg, rgba(244,102,48,1) 0%, rgba(247,187,25,1) 100%);
  border-radius: 6px;
}

.community-card.unified .bucket-wrapper{max-width: 57%;}
.bucket-wrapper .applicants{
  display:flex;
  justify-content: space-between;
  font-size:80%;
  margin-bottom:3px;
}
.go-arrow{
  background:#F46630;
  width:34px;
  height: 34px;
  border-radius: 50em;
  position: absolute;
  bottom:0;
  right:20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* animated properties */
  opacity:0;
  
  transform: translateY(80%) rotateX(62deg);
  transition: all .5s ease;
}
.go-arrow svg{width:45%;}

.go-anywhere-card{
  background-color:#E5322B;
  background-image: url(images/globe-element-426-red.png);
  padding: 8% 10%;
  background-repeat: no-repeat;
  background-size: auto 110%;
  background-position: top 50% left -5%;
  display:block;
  position: relative;
}
.go-anywhere__title{
  font-size:1.7rem;
  font-weight: 600;
  margin-bottom:20px;
  line-height:1.2em;
}
.go-anywhere__desc{color:#fff;font-size:1rem; text-transform: uppercase; font-weight: 600;}
.go-anywhere-card .send-me-btn{
  position: absolute;
  right: 4%;
  bottom: -28px;
  background-color: #000;
}

.community-card-skeleton{overflow: hidden;}
.skeleton__content-wrapper{padding: 36px 30px; flex-basis: 60%;}
.skeleton__meta-wrapper{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px 14px;
  margin:15px 0px 18px;
}

.skeleton__image-placeholder{
  min-height: 100%;
  background-color: rgba(0,0,0,.06);
  flex-basis:40%;
}


/***********************************/
/********* Success Page ************/
/***********************************/
.page-success .container{ max-width: 940px;}
.page-success .col.main > .section{
  min-height: 90vh;
  display: flex;
  flex-direction: column;
}
.success-p{max-width: 704px;}
.success-title{font-size:40px;}
.success-p{margin-bottom:40px;}
.success-video{
  /* width: 320px;
  height: auto; */
  border: 3px solid #000;
  border-radius: 20px;
  margin-bottom: 27px;
}

.page-success .footer-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}
.page-success .col-left{max-width: 35%;}
.page-success .col-right{max-width: 40%;}
.footer-row .emblems{
  width: 224px;
}

.location-tag{
  position:absolute;
  right:-12px;
  top:22px;
  display:inline-flex;
  justify-content: center;
  align-items: flex-end;
  background:rgba(255,255,255,1);
  border: 2px solid #000;
  z-index: 1;
  padding: 6px 14px 5px;
  
  text-transform:uppercase;
  flex-direction: column;
  
  letter-spacing: 0;
}
.location-tag__label{font-size:.45rem; letter-spacing: 0; color:#171717; font-weight: bold; margin-bottom:2px;}
.location-tag__text{font-size: .6rem; font-weight:600;}

.location-tag__icon svg{
  height: 1.05em;
  position: relative;
  top: 1px;
  margin-left:3px;
}

/***********************************/
/**** Single Top Community Page ****/
/***********************************/

.page-single-community .logo-desktop{
  width:175px;
  align-self: flex-start;
  margin-top: 14px;
}

.powered-by-single{width: 111px;}

.page-single-community .content-row{gap:5%;}

.page-single-community .App-header{
  padding-bottom:15px;
  border-bottom:1.7px solid #cfc5ba;
  margin-bottom:20px;
} 

.page-single-community .community-image{
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1080/1920;
  display:block;
}

.community-title-row{margin-bottom:20px;}

.community-top-stats{
  background-color:rgba(0,0,0,.04);
  border-radius:20px;
  padding:22px 30px;
  position: relative;
  margin-bottom:25px;
  flex-wrap: nowrap;
}
.community-top-stats::before{
  content:'';
  position:absolute;
  left:0;
  top:0;
  right:0;
  bottom:0;
  background-image: url(images/globe-black.svg);
  background-repeat: no-repeat;
  background-position: center right -5%;
  background-size: 60% auto;
  opacity:.03;
  border-radius:20px;
}

.community-top-stats .row{
  display: flex;
  gap: 2%;
  justify-content: space-between;
  position: relative;
}

.row .top-stats-sub-wrapper{
  display: flex;
  flex-wrap: nowrap;
}

.single-affiliation-wrapper{
  text-align: right;
  flex-basis: 35%;
  font-weight: bold;
  font-size: .75rem;
}
.single-affiliation-label{
  font-size: 80%;
}

.top-stat{text-transform: uppercase; font-size:.8rem; font-weight:bold;}
.top-stat-label{font-size:80%; opacity:.5; white-space: nowrap;}

.page-single-community .community-name{
  font-size: 270%;
  max-width: 79%;
  line-height: 1;
  margin: 4px 0;
  flex-basis: 63%;
}
.page-single-community .community__info{
  font-size: .85rem;
  line-height: 1.6em;
}

.page-single-community .community--desc{margin-bottom:34px; margin-top: 0;}
.meta-button-wrapper{display:flex; justify-content: space-between; gap:10px;}

.back-to-communities{color:#000;opacity:.65; text-transform: uppercase; font-size:70%; font-weight:600; margin-bottom:1.8em; display: inline-block;}
.video-col{max-width: 35%;}
.video-wrapper{
  position: relative; 
  background-color: rgba(0, 0, 0, .2); 
  border-radius: 20px; 
  border: 4px solid;
  overflow:hidden;
}
.play-button{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(7px);
  width:80px; 
  aspect-ratio: 1; 
  background:rgba(255,255,255,.3);
  border-radius: 20em;
  cursor:pointer;
  z-index:2;
}

.play-button svg{position: relative; left:2px; fill:#FA0015;}

.community-video{
  background: #000;
  border-radius:14px;
  box-shadow: 0 12px 14px rgba(0,0,0,.2); aspect-ratio: 1080/1920;
  display: block;
}
.page-single-community .bucket-wrapper{margin-bottom:40px;}
.page-single-community .community__meta{
  font-size: 68%;
  line-height: 1.4;
  flex-grow:1;
}

button{position: relative;}
button.loading{opacity: .85;}
button.loading .btn-content{opacity:0;}
button.loading .btn-loading{opacity:1;}
.send-me-btn{
  
  font-family:interstate-mono;
  text-transform:uppercase;
  color:#fff; background:#FA0015; 
  padding: 15px 6%;
  border-radius:20em; 
  border:none; 
  font: inherit;
	cursor: pointer;
	outline: inherit;
  min-width: 144px;
  height: fit-content;
  font-weight:600;
}
.send-me-btn .btn-content{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-loading{
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  opacity:0;
}

.send-me-btn .btn-icon{
  width:1.2em;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.community__button{
  margin-top:20px;
  width: 100%;
  color: #fff;
  background: rgba(0,0,0,.2);
  border: none;
  border-radius: 5px;
  padding: 15px 10px;
  font-weight: 600;
}


video{
  width: 100%;
    height: auto;
    /* object-fit: cover; breaks full screen */
    /* transition: all .5s cubic-bezier(0.87, 0, 0.13, 1); */

}

.back-mobile svg{transform: rotate(180deg);}



/* Above Tablet */
@media screen and (min-width:1000px){
  a .community-card:hover{
    /* move this to above mobile media query. */
    background: #171717;
    color:#FCFCFC;
    transform:scale(1.02);
    box-shadow: 0 15px 16px rgba(0,0,0,.1);
  }
  a .community-card:hover .progress-bar{background: rgba(255,255,255,.3);}
  a .community-card:hover .go-arrow{opacity:1; transform: translateY(50%);}
}

/* Below Tablet */
@media screen and (max-width:999px){
  .container{max-width: 690px;}
  .home-main-row{grid-template-columns: 1fr;}
  .hide-below-desktop{display:none!important;}
  .page-communities .main{min-width:100%; margin-right:auto;}

  .community-card{font-size:.7rem;}
}


/* Above Mobile */
@media screen and (min-width:641px) {
  .back-mobile{display:none;}
  .mobile-only{display: none;}
  
}

/* Mobile Only */
/* Was 480px experimenting with swapping at 640px. */
@media screen and (max-width: 640px) {
  .container{max-width: 512px;}
  .content{padding-top:40px;}
  .hide-below-tablet{display:none!important;}
  h3{font-size:24px;}

  .logo{width:120px;}
  .logos-wrapper .send-logo {
    width: 10em;
  }
  .powered-by-logo-wrapper{
    font-size: .6rem;
  }
  .welcome-title{
    margin-bottom: 8px;
    margin-top: 42px;
    font-size: 1.6em;
  }



  .community-card{
    display:block;
    font-size: .9rem;
    margin-bottom: 28px;
    box-shadow: 0 9px 14px rgba(0,0,0,.1);
  }
  
  .community-card.unified{margin-bottom:54px;}

  .community__image{border-right:0; border-bottom:2px solid var(--border-color); border-radius: 12px 12px 0 0;}
  .community__image img{height:100%; object-fit: cover;}

  .community__image-bg{height: 100%; aspect-ratio: 1.4;}

  .community__image .name-affiation-wrapper{
    position:absolute; 
    left:0;right:0; top:0; bottom:0; 
    display:flex; 
    align-items: flex-end; 
    color:#fff;
    padding:0 18px;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,.6) 0px, rgba(0,0,0,0) 80px);
    background-repeat: no-repeat;
    background-position: bottom;
  }
  .community-card .com-label{letter-spacing: 0; font-size: .55rem;}

  .community__name, .community__affiliation{ font-size:107%;}

  .community__affiliation{opacity:1;}
  .community-card.unified .community__info{padding-bottom: 43px;}

  .community-card-skeleton{
    padding-top: 40%;
  }

  .page-single-community .community__info{margin-bottom:50px;}


  .community__desc{
    font-size:.7rem;
    /* CSS Method for truncating to x lines. */
    -webkit-line-clamp: 5;
    margin-bottom:18px;
    letter-spacing: 0;
  }
  .community-card.unified .community__desc{-webkit-line-clamp: 7;}
  
  .community__meta{
    font-size:60%;
    margin-bottom: 12px;
  }



  .meta-icon{display: none;}

  .community-card .community__meta div:last-child{margin-bottom: 5px;}

  .community-card .community__meta .meta-item:last-child{margin-bottom: 10px;}

  .bucket-wrapper .applicants{font-size: 64%;}
  
  .community-card.unified .bucket-wrapper{max-width:100%;}

  /* single community page: */
  .back-desktop-row{display:none;}
  .page-single-community .content-row{flex-direction: column;}
  .page-single-community .content{padding:0;}
  .page-single-community .section{
    padding:20px; 
    padding-top: 50px;
    margin-top: -50px;
  }

  
  .page-single-community .row{gap:0;}
  .page-single-community .col.main{z-index:1; padding-top:200px; margin-top:-200px;  /* raises background gradient so it starts 200 pixels above content.: */ } 
  .video-wrapper{height: 62vh; margin-top: 90px;}
  .single-community-bg-image-overlay{
    background: rgba(0,0,0,.23);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
  .community-video,.page-single-community .community-image{
    position: fixed;
    left: 0;
    top: 0;
    border-radius: 0;
    width: 100%;
    height: 100%;
    object-position: top;
    object-fit: cover;
  }
  .video-col{max-width: 100%;}
  .page-single-community .community-name{
    margin: 0 0 18px;
    font-size: 156%;
    max-width: 100%;
    line-height: 1;
  }
  .page-single-community .App-header {
    justify-content: space-between;
    position: fixed;
    top: 26px;
    width: 100%;
    left: 0;
    padding-inline: 23px;
    box-sizing: border-box;
  }

  .page-single-community .main{
    color:#E5E5E5; 
    background: rgb(0,0,0);
    background: linear-gradient(180deg,transparent 0%,rgba(0,0,0,.95) 320px);/* Starts as transparent at top moves to black 200px down and then stays black forever.*/
  }
  .meta-button-wrapper{
    flex-direction: column;
    gap: 22px;
  }
  .send-me-btn{
    font-size: 19px;
    padding: 22px 40px;
    margin-bottom: 20px;
  }

  .community-card.unified .send-me-btn{
    font-size: 14px;
    padding: 17px 37px;
    margin-bottom: -4px;
  }
  .go-anywhere-card{ padding: 16% 10% 62px; margin-bottom:48px; }
  .go-anywhere-card .send-me-btn{font-size: 12px; bottom: -46px; padding: 22px 40px;}

  .page-single-community .community__meta{font-size: 74%;}

  
}

/* loading spinner: */
.loader {
  width: 21px;
  height: 21px;
  display: inline-block;
  position: relative;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  border: 2px solid #FFF;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}
.loader::after {
  animation-delay: 1s;
  opacity: 0;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}